import { Container } from '@material-ui/core'
import React from 'react'
import { Tabs } from './library'

function ReportsWrapper({currentTab, children}) {
    return (
        <>
            <Tabs 
                id="report-tabs"
                title="Reports"
                tabs={
                    [{
                        label: "Symptoms",
                        to: '/reports'
                    },
                    {
                        label: "Spirometer",
                        to: '/reports/spirometer'
                    },
                    {
                        label: "C-ACT",
                        to: '/reports/c-act'
                    }]
                }
                
                initialTab={currentTab} 
            />
            <Container maxWidth="md">
                {children}
            </Container>
        </>
    )
}

export default ReportsWrapper
