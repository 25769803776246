import { DateTime } from "luxon"

function parseSpirometerReports(userData) {
    const spiro_uuids = Object.keys(userData).filter((uuid) => userData[uuid].type === 'node--spirometer_result' && typeof userData[uuid].attributes !== 'undefined')
    const reports = []
    if(spiro_uuids.length > 0){
        for (const uuid of spiro_uuids) {
            const entity = userData[uuid]
            const report = parseSpirometerItemFn(entity)
            if(report){
                reports.push(report)
            }
        }
    }
    return reports
}

function parseSpirometerItemFn(entity) {
    let report = null
    const id = typeof entity.id !== 'undefined' ? entity.id : entity.local_id
    const date = typeof entity?.attributes?.field_test_date_iso !== 'undefined' ? DateTime.fromISO(entity.attributes.field_test_date_iso) : null
    const fvc_actual = typeof entity?.attributes?.field_fvc_actual !== 'undefined' ? parseFloat(entity.attributes.field_fvc_actual) : null
    const fvc_predicted = typeof entity?.attributes?.field_fvc_predicted !== 'undefined' ? parseFloat(entity.attributes.field_fvc_predicted) : null
    const fev1_actual = typeof entity?.attributes?.field_fev1_actual !== 'undefined' ? parseFloat(entity.attributes.field_fev1_actual) : null
    const fev1_predicted = typeof entity?.attributes?.field_fev1_predicted !== 'undefined' ? parseFloat(entity.attributes.field_fev1_predicted) : null
    const fev1_fvc = typeof entity?.attributes?.field_fev1_fvc !== 'undefined' ? parseFloat(entity.attributes.field_fev1_fvc) : null
    if(
        date !== null &&
        // fvc_actual !== null && 
        fvc_predicted !== null && 
        // fev1_actual !== null && 
        fev1_predicted !== null && 
        fev1_fvc !== null
    ){
        const fev1_level = fev1_actual >= 80 ? 4 : 0
        const fev1_predicted_level = fev1_predicted < 75 ? 0 : fev1_predicted < 81 ? 1 : 4
     // const fvc_level = null
        const fvc_level = fvc_predicted < 75 ? 0 : fvc_predicted < 81 ? 1 : 4
        const fev1_fvc_level = fev1_fvc < 75 ? 0 : fev1_fvc <  81 ? 1 : 4
        report = {
            date,
            uuid: id,
            fev1: {
                predicted: fvc_predicted,
                predicted_level: fev1_predicted_level,
                // actual: fev1_actual,
                // level: fev1_level
                level: fev1_predicted_level
            },
            fvc: {
                predicted: fev1_predicted,
                predicted_level: fvc_level,
                // actual: fvc_actual,
                level: fvc_level
            },
            fev1_fvc: {
                // predicted: '-',
                predicted: fev1_fvc,
                // actual: fev1_fvc,
                level: fev1_fvc_level
            }
        }
    }
    return report
}

export const parseSpirometerItem = parseSpirometerItemFn

export default parseSpirometerReports

