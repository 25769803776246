import React, { useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import ReportsWrapper from '../../components/ReportsWrapper'
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ImpactContext } from '../../components/Layout'
import { CircularProgress, ReportCard, Spacer, Typography, ScoreScale, Section, CardCollapse } from '../../components/library'
import parseSpirometerReports from '../../utils/parseSpirometerReports'
import { UserContext } from '@parallelpublicworks/entitysync'

const useStyles = makeStyles((theme) => ({
    legend: {
        display: 'flex',
        '& > *:first-child': {
            paddingRight: theme.spacing(2)
        }
    }
}))

function spirometer() {
    const classes = useStyles()
    const { userData, user } = useContext(ImpactContext)
    const userContext = useContext(UserContext)
    const [reports, setReports] = useState(null)

    useEffect(() => {
        if(userData !== null && reports === null){
            setReports(parseSpirometerReports(userData))
        }
    }, [userData])

    if(userData === null || reports === null) return <CircularProgress />

    return (
        <ReportsWrapper currentTab={1}>
            <Spacer />

            {reports.length === 0 && (
                <Section title="Spirometer Reports">
                    <Typography variant="caption">
                    No data
                    </Typography>
                </Section>
            )}

          {reports.map(report => {
            return (
                <ReportCard 
                    title={report.date.toLocaleString(DateTime.DATE_MED)}
                    to="/spirometry-detail-view"
                    uuid={report.uuid}
                    grades={[
                        // {
                        //     label: 'FEV1',
                        //     value: `${report.fev1.actual} L`,
                        //     level: report.fev1.level
                        // },
                        {
                            label: ' FEV1/FVC  ',
                            value: `${report.fev1.predicted}%`,
                            level: report.fev1.level
                        },
                    ]} 
                />
            )  
          })}
          
          <Spacer />
          <CardCollapse title="Legend">
                <div className={classes.legend}>
                    <Typography variant="body1" shade="dark"><strong>FEV1</strong></Typography>
                    <Typography variant="body1">is the amount of air a person can forcefully exhale in 1 second. Higher percentages indicate better asthma control.</Typography>
                </div>
                <ScoreScale
                    levels={[
                        {value: 80, label: '0-80% poorly controlled', level: 0},
                        {value: 160, label: '>=80% well controlled', level: 4},
                    ]}
                    hideLastNum
                />
                <Spacer />
                <div className={classes.legend}>
                    <Typography variant="body1" shade="dark"><strong>FEV1/FVC</strong></Typography>
                    <Typography variant="body1">is the proportion of air a person can exhale in 1 second to their full lung capacity. Higher percentages indicate better asthma control.</Typography>
                </div>
                <ScoreScale
                    levels={[
                        {value: 75, label: '0-75% poorly controlled', level: 0},
                        {value: 80, label: '75-80% partially controlled', level: 1},
                        {value: 130, label: '>=80% well controlled', level: 4},
                    ]}
                    hideLastNum
                />
          </CardCollapse>
        </ReportsWrapper>
    )
}

export default spirometer
